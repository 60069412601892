import React from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';

import SelectComponent from 'antd/es/select/index';
import 'antd/es/select/style/css';

import './styles.scss';

const { Option } = SelectComponent;

const Select = React.forwardRef(
  (
    {
      className,
      options,
      errorMessage,
      name,
      value,
      label,
      placeholder,
      defaultOptionLabel,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={className}>
        {label && props.id ? (
          <label className="label" htmlFor={props.id}>
            {label}
          </label>
        ) : null}

        <SelectComponent
          className="select"
          name={name}
          value={value === '' ? undefined : value}
          ref={ref}
          placeholder={placeholder}
          showArrow={false}
          {...props}
        >
          {defaultOptionLabel ? (
            <Option key="default" value="">
              {defaultOptionLabel}
            </Option>
          ) : null}

          {options.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </SelectComponent>
        <AnimateHeight height={errorMessage ? 'auto' : 0} duration={300}>
          <p className="input__error-message">{errorMessage}</p>
        </AnimateHeight>
      </div>
    );
  },
);

Select.displayName = 'Select';

Select.defaultProps = {
  type: 'text',
  options: [],
};

Select.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  defaultOptionLabel: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Select;
