import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ShareBar from 'components/ShareBar';

import './styles.scss';

const GetInTouch = (props) => {
  return (
    <section className="get-in-touch">
      <div className="get-in-touch__container">
        <h2 className="get-in-touch__title">Get in touch</h2>
        <p className="get-in-touch__info">
          Press the button below and we will contact you on your email with further suggestions and
          examples of what we have done for people in your position.
        </p>
        <p className="get-in-touch__info">
          We will try and get back to you as soon as possible but it could take a couple days.
        </p>
        <div className="get-in-touch__controllers">
          <Button
            className="get-in-touch__controllers__contact-me"
            label="Yes, contact me"
            onClick={props.onContactMeClick}
            arrowRight
          />
          <Button
            className="get-in-touch__controllers__send-me-results"
            label="or just email me the results"
            onClick={props.onEmailMeResultsClick}
          />
        </div>

        <div className="get-in-touch__share">
          <p className="get-in-touch__share__title">
            How does your friends and colleagues score in this test?
          </p>
          <ShareBar className="get-in-touch__share__controllers" />
        </div>
      </div>
    </section>
  );
};

GetInTouch.propTypes = {
  onContactMeClick: PropTypes.func.isRequired,
  onEmailMeResultsClick: PropTypes.func.isRequired,
};

export default React.memo(GetInTouch);
