import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ArrowRight from 'assets/img/icons/arrow-right.svg';

import './styles.scss';

const Button = React.forwardRef(
  (
    { className, arrowRight, label, type, children, secondary, white, disabled, onClick, ...props },
    ref,
  ) => {
    const clickHandler = useCallback(
      (e) => {
        e.persist();
        // reset animation
        e.target.classList.remove('button--animate');

        e.target.classList.add('button--animate');

        setTimeout(() => {
          e.target.classList.remove('button--animate');
        }, 125);

        if (type !== 'submit') {
          onClick();
        }
      },
      [onClick, type],
    );

    return (
      <button
        className={cn(
          'button',
          className,
          { 'button--primary': !secondary },
          { 'button--secondary-black': secondary && !white },
          { 'button--secondary-white': secondary && white },
        )}
        ref={ref}
        type={type}
        onClick={clickHandler}
        disabled={disabled}
        {...props}
      >
        <span>
          {label || children}
          {arrowRight ? <img className="button__icon" src={ArrowRight} alt="arrow-right" /> : null}
        </span>
      </button>
    );
  },
);

Button.displayName = 'Button';

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  arrowRight: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  type: 'button',
  arrowRight: false,
  disabled: false,
  secondary: false,
};

export default Button;
