import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const Score = ({ score, className, label = '' }) => {
  const barSize = 503 + (score * 503) / 100;
  const pointer = -107.5 + (score * 214) / 100;

  return (
    <svg
      className={cn('score', className)}
      viewBox="0 0 324 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" y="0" width="324" height="324" />
      <text x="130" y="151" className="value">
        {score} <span>%</span>
      </text>
      <text x="195" y="125" className="percentage">
        %
      </text>
      <text x="130" y="186" className="label">
        {label}
      </text>
      <path
        opacity="0.12"
        d="M292.281 187C296.001 174.653 298 161.56 298 148C298 73.4416 237.558 13 163 13C88.4416 13 28 73.4416 28 148C28 161.56 29.9992 174.653 33.7188 187"
        stroke="#933B4F"
        strokeWidth="19"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M292.281 187C296.001 174.653 298 161.56 298 148C298 73.4416 237.558 13 163 13C88.4416 13 28 73.4416 28 148C28 161.56 29.9992 174.653 33.7188 187"
        stroke="#2035B1"
        strokeWidth="25"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeDasharray="503"
        strokeDashoffset={barSize}
      />
      <svg
        x="0"
        y="-8"
        width="324"
        height="324"
        viewBox="0 0 324 324"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform={`rotate(${pointer} 163 156)`}>
          <rect x="0" y="0" width="324" height="324" />
          <svg
            x="144"
            width="41"
            height="67"
            viewBox="0 0 42 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="21" cy="21" r="18" fill="#2035B1" stroke="#D36391" strokeWidth="4" />
            <path
              d="M21.0218 53.6053L30.567 63.3436L11.4766 63.3436L21.0218 53.6053Z"
              fill="#2035B1"
            />
          </svg>
        </g>
      </svg>
    </svg>
  );
};

Score.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  score: PropTypes.number.isRequired,
};

export default React.memo(Score);
