import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import Button from 'components/Button';
import TextInput from 'components/Inputs/TextInput';
import Checkbox from 'components/Inputs/Checkbox';

import { Actions } from 'reducers/user';

import ROUTES from 'consts/routes';
import { FORM_ERROR_MESSAGES } from 'consts/errors';
import { pageRegistrationVisited } from 'utils/analytics';
import { validateEmail } from 'utils/user';

import './styles.scss';

const UserRegistration = (props) => {
  useEffect(() => {
    pageRegistrationVisited();
  }, []);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: { email: props.email, policyAccepted: props.policyAccepted },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = useCallback((data) => {
    props.loginWithEmail(data.email);
  });

  return (
    <div className="user-registration">
      <h1 className="user-registration__title">Ready to do the CXI self-assessment test? </h1>
      <p className="user-registration__info">
        We’ll just need an email address first, so that we can send you the test. We value your
        privacy and will never share your information.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          className="user-registration__email-field"
          id="email"
          type="email"
          name="email"
          label="Your email"
          placeholder="Your email"
          ref={register({
            required: FORM_ERROR_MESSAGES.REQUIRED,
            validate: (email) => validateEmail(email) || FORM_ERROR_MESSAGES.EMAIL_NOT_VALID,
          })}
          errorMessage={errors.email?.message}
        />
        <Checkbox
          id="policy-accepted"
          className="user-registration__policy-accepted"
          name="policyAccepted"
          label="I approve that BBH Stockholm and Publicis Groupe stores my email and answers."
          ref={register({ required: FORM_ERROR_MESSAGES.REQUIRED })}
          errorMessage={errors.policyAccepted?.message}
        />
        <Link
          className="user-registration__read-policy"
          to={ROUTES.PRIVANCY_POLICY}
          label="Read privacy policy"
        />
        <Button
          className="user-registration__get-the-link"
          arrowRight
          label="Send me the link"
          type="submit"
          disabled={props.isLoading}
        />
      </form>
    </div>
  );
};

UserRegistration.propTypes = {
  email: PropTypes.string,
  isLoading: PropTypes.bool,
  policyAccepted: PropTypes.bool,
  loginWithEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.global.isLoading > 0 || false,
  email: state.user.email || '',
  policyAccepted: state.user.policyAccepted || false,
});

const mapDispatchToProps = (dispatch) => ({
  loginWithEmail: (email, policyAccepted) =>
    dispatch(Actions.loginWithEmail(email, policyAccepted)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistration);
