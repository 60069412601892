import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import './styles.scss';

const SubmitSurveyScreen = ({ onSubmit }) => {
  return (
    <div className="final">
      <h1 className="final__title">
        Fantastic! <br />
        You completed all the steps!
      </h1>
      <p className="final__subtitle">Submit your answers to get your final score.</p>
      <Button className="final__submit" label="submit results" onClick={onSubmit} arrowRight />
    </div>
  );
};

SubmitSurveyScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SubmitSurveyScreen;
