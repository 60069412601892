import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionTypes, GlobalActions } from 'reducers/global';

import { getConfig } from 'api/global';
import { getError } from 'utils/notifications';

function* fetchConfig() {
  try {
    yield put(GlobalActions.showLoader());

    const rawConfig = yield call(getConfig);

    if (rawConfig.empty) {
      throw new Error('Config is empty');
    } else {
      const config = Object.fromEntries(rawConfig.docs.map((doc) => [doc.id, doc.data()]));
      yield put(GlobalActions.fetchConfigSuccess(config));
    }
  } catch (e) {
    yield put(GlobalActions.showNotification(...getError('Failed to fetch general data.', e)));
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

export default function* saga() {
  yield takeLatest(ActionTypes.FETCH_CONFIG, fetchConfig);
}
