import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

export const { Types: ActionTypes, Creators: GlobalActions } = createActions(
  {
    showLoader: [],
    hideLoader: [],

    fetchConfig: [],
    fetchConfigSuccess: ['data'],

    showNotification: ['key', 'notification'],
    hideNotification: ['key', 'dismissAll'],
    removeNotification: ['key'],
  },
  { prefix: 'GLOBAL/' },
);

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  isLoading: 0,
  config: null,
  notifications: [],
};

/* ------------- Reducer functions ------------- */

const showLoader = (state) => ({
  ...state,
  isLoading: state.isLoading + 1,
});

const hideLoader = (state) => ({
  ...state,
  isLoading: state.isLoading - 1,
});

const showNotification = (state, { key, notification }) => ({
  ...state,
  notifications: [
    ...state.notifications,
    {
      key: key,
      ...notification,
    },
  ],
});

const hideNotification = (state, { key, dismissAll = false }) => ({
  ...state,
  notifications: state.notifications.map((notification) =>
    dismissAll || notification.key === key
      ? { ...notification, dismissed: true }
      : { ...notification },
  ),
});

const removeNotification = (state, { key }) => ({
  ...state,
  ...state,
  notifications: state.notifications.filter((notification) => notification.key !== key),
});

const fetchConfigSuccess = (state, { data }) => ({
  ...state,
  config: data,
});

/* ------------- Hook  ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [ActionTypes.SHOW_LOADER]: showLoader,
  [ActionTypes.HIDE_LOADER]: hideLoader,

  [ActionTypes.SHOW_NOTIFICATION]: showNotification,
  [ActionTypes.HIDE_NOTIFICATION]: hideNotification,
  [ActionTypes.REMOVE_NOTIFICATION]: removeNotification,

  [ActionTypes.FETCH_CONFIG_SUCCESS]: fetchConfigSuccess,
});
