import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ROUTES from 'consts/routes';
import { Actions } from 'reducers/user';
import { getProgress } from 'utils/survey';
import { pageAddDetailsVisited } from 'utils/analytics';

import './styles.scss';

import ProgressBar from './ProgressBar';
import SectionScreen from './SectionScreen';
import QuestionScreen from './QuestionScreen';
import SubmitSurveyScreen from './SubmitSurveyScreen';

class Survey extends PureComponent {
  state = {
    currentScreenIndex: this.currentScreenIndex,
  };

  componentDidMount() {
    pageAddDetailsVisited();
    if (!this.props.userId) {
      this.props.history.push(ROUTES.REGISTRATION);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.screenId !== this.props.match.params.screenId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentScreenIndex: this.currentScreenIndex });
    }
  }

  onPrevClick = (newAnsver) => {
    const [questionId, optionId] = Object.entries(newAnsver)[0];

    this.props.setAnswer(questionId, optionId);
    this.moveToPrevScreen();
  };

  onNextClick = (newAnsver) => {
    const [questionId, optionId] = Object.entries(newAnsver)[0];

    this.props.setAnswer(questionId, optionId);
    this.moveToNextScreen();
  };

  get currentScreenIndex() {
    const screenIndex = this.props.screens.findIndex(
      (screen) => screen.id === this.props.match.params.screenId,
    );

    if (screenIndex !== -1) return screenIndex;
    return 0;
  }

  get answer() {
    return this.props.answers[this.props.screens[this.state.currentScreenIndex]?.id] || '';
  }

  moveToNextScreen = () => {
    this.props.history.push(
      ROUTES.SURVEY.replace(
        ':screenId?',
        this.props.screens[this.state.currentScreenIndex + 1]?.id,
      ),
    );
  };

  moveToPrevScreen = () => {
    this.props.history.push(
      ROUTES.SURVEY.replace(
        ':screenId?',
        this.props.screens[this.state.currentScreenIndex - 1]?.id,
      ),
    );
  };

  render() {
    const { currentScreenIndex } = this.state;
    const progress = getProgress(this.props.screens, this.props.answers);
    const currentScreen = this.props.screens[currentScreenIndex] || {};

    return (
      <div className="survey">
        <div className="survey__container">
          {currentScreen.type === 'section' ? (
            <SectionScreen
              key={currentScreen.id}
              title={currentScreen.title}
              description={currentScreen.description}
              imageUrl={currentScreen.imageUrl}
              imageAlt={currentScreen.imageAlt}
              step={currentScreen.step}
              onPrevClick={currentScreenIndex > 0 ? this.moveToPrevScreen : null}
              onNextClick={this.moveToNextScreen}
            />
          ) : null}

          {currentScreen.type === 'question' ? (
            <QuestionScreen
              key={currentScreen.id}
              id={currentScreen.id}
              title={currentScreen.title}
              description={currentScreen.description}
              step={currentScreen.step}
              options={currentScreen.options}
              answer={this.answer}
              onPrevClick={this.onPrevClick}
              onNextClick={this.onNextClick}
            />
          ) : null}

          {currentScreen.type === 'final' ? (
            <SubmitSurveyScreen onSubmit={this.props.submitUserSurvey} />
          ) : null}

          <ProgressBar progress={progress} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  screens: state.survey.screens || [],
  answers: state.user.answers || {},
  userId: state.user.id || '',
});

const mapDispatchToProps = (dispatch) => ({
  setAnswer: (questionId, optionId) => dispatch(Actions.setAnswer(questionId, optionId)),
  submitUserSurvey: () => dispatch(Actions.submitUserSurvey()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
