import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import Button from 'components/Button';
import TextInput from 'components/Inputs/TextInput';
import Select from 'components/Inputs/Select';

import { Actions } from 'reducers/user';

import { FORM_ERROR_MESSAGES } from 'consts/errors';
import { pageAddDetailsVisited } from 'utils/analytics';
import './styles.scss';

const getValueFromController = ([selected]) => selected;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const AddDetails = (props) => {
  useEffect(() => {
    props.confirmLoginWithEmail();
    props.fetchUserFormData();
    pageAddDetailsVisited();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { register, handleSubmit, setValue, errors, control } = useForm();

  const prevPersonalData = usePrevious(props.personalData);

  useEffect(() => {
    if (!Object.keys(prevPersonalData || {}).length && Object.keys(props.personalData).length) {
      const {
        fullName,
        companyName,
        companyIndustry,
        companySize,
        jobSeniority,
        jobRole,
      } = props.personalData;

      const values = [
        fullName && { fullName },
        companyName && { companyName },
        companyIndustry && { companyIndustry },
        companySize && { companySize },
        jobSeniority && { jobSeniority },
        jobRole && { jobRole },
      ].filter(Boolean);

      setValue(values);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevPersonalData, props.personalData]);

  return (
    <div className="add-details">
      <section className="add-details__info">
        <h1 className="add-details__info__title">Welcome!</h1>
        <p>
          The survey has 30 questions and takes about 15-20 min to answer.
          <br />
          <br />
          Your answers are autosaved so you can leave and return at any time by clicking the link in
          the email we’ve just sent.
        </p>
      </section>
      <form className="add-details__form" onSubmit={handleSubmit(props.addUserInformation)}>
        <h2 className="title">Please fill out the details below</h2>
        <TextInput
          className="fields"
          id="name"
          name="fullName"
          label="First and Last name"
          placeholder="First and Last name"
          ref={register({ required: FORM_ERROR_MESSAGES.REQUIRED })}
          errorMessage={errors.fullName?.message}
        />
        <TextInput
          className="fields"
          id="company-name"
          name="companyName"
          label="Company Name"
          placeholder="Company Name"
          ref={register({ required: FORM_ERROR_MESSAGES.REQUIRED })}
          errorMessage={errors.companyName?.message}
        />
        <Controller
          name="companyIndustry"
          control={control}
          rules={{ required: FORM_ERROR_MESSAGES.REQUIRED }}
          onChange={getValueFromController}
          as={
            <Select
              id="company-industry"
              className="fields"
              label="Company Industry"
              placeholder="Company Industry"
              options={props.formData.companyIndustry}
              errorMessage={errors.companyIndustry?.message}
            />
          }
        />
        <Controller
          name="companySize"
          control={control}
          rules={{ required: FORM_ERROR_MESSAGES.REQUIRED }}
          onChange={getValueFromController}
          as={
            <Select
              id="company-size"
              className="fields"
              label="Company Size"
              placeholder="Company Size"
              options={props.formData.companySize}
              errorMessage={errors.companySize?.message}
            />
          }
        />
        <Controller
          name="jobSeniority"
          control={control}
          rules={{ required: FORM_ERROR_MESSAGES.REQUIRED }}
          onChange={getValueFromController}
          as={
            <Select
              id="job-seniority"
              className="fields"
              label="Job Seniority"
              placeholder="Job Seniority"
              options={props.formData.jobSeniority}
              errorMessage={errors.jobSeniority?.message}
            />
          }
        />
        <Controller
          name="jobRole"
          control={control}
          rules={{ required: FORM_ERROR_MESSAGES.REQUIRED }}
          onChange={getValueFromController}
          as={
            <Select
              id="job-role"
              className="role"
              label="Job Function"
              placeholder="Job Function"
              options={props.formData.jobRoles}
              errorMessage={errors.jobRole?.message}
            />
          }
        />
        <Button
          className="start-the-test"
          label="Start the test"
          onClick={handleSubmit(props.addUserInformation)}
          arrowRight
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  personalData: state.user.personalData || {},
  userId: state.user.id || '',
  formData: state.user.formData || {},
});

const mapDispatchToProps = (dispatch) => ({
  addUserInformation: (data) => dispatch(Actions.addUserInformation(data)),
  fetchUserFormData: () => dispatch(Actions.fetchUserFormData()),
  confirmLoginWithEmail: () => dispatch(Actions.confirmLoginWithEmail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDetails);
