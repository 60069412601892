import React, { forwardRef, useCallback, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';
import { pageQuestionVisited } from 'utils/analytics';

import './styles.scss';

const Option = forwardRef(({ id, title, name, onNextClick, ...props }, ref) => {
  const onClick = useCallback(() => onNextClick({ [name]: id }), [id, name, onNextClick]);

  return (
    <li onClick={onClick}>
      <input id={id} type="radio" ref={ref} name={name} value={id} {...props} />
      <label htmlFor={id}>{title}</label>
    </li>
  );
});

Option.displayName = 'Option';

const QuestionScreen = ({
  id,
  title,
  description,
  step,
  options,
  answer,
  onNextClick,
  onPrevClick,
}) => {
  const { register, handleSubmit } = useForm({ defaultValues: { [id]: answer } });
  const [active, setActive] = useState(true);

  useEffect(() => {
    pageQuestionVisited(`Question: ${step.current} of ${step.of} ${title}`);
  }, [step, title]);

  const onSubmitPrev = useCallback(() => {
    setActive(false);
    setTimeout(() => {
      handleSubmit(onPrevClick)();
    }, 250);
  }, [onPrevClick, handleSubmit]);

  const onSubmitNext = useCallback(() => {
    setActive(false);
    setTimeout(() => {
      handleSubmit(onNextClick)();
    }, 250);
  }, [onNextClick, handleSubmit]);

  return (
    <CSSTransition in={active} timeout={250} classNames="fade" unmountOnExit>
      <form className="question" onSubmit={onSubmitNext}>
        <div className="question__container">
          <p className="question__steps">{`${step.current} of ${step.of}`}</p>
          <h1 className="question__title">{title}</h1>

          {description ? (
            <p
              className="question__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : null}

          <ul className="question__options">
            {options.map((option) => (
              <Option
                key={option.id}
                name={id}
                id={option.id}
                value={option.id}
                title={option.title}
                ref={register()}
                onNextClick={onNextClick}
              />
            ))}
          </ul>

          <div className="question__controllers">
            <Button className="question__controllers__prev" onClick={onSubmitPrev} secondary white>
              <span>‹</span> Previous
            </Button>

            {answer ? (
              <Button
                className="question__controllers__next"
                onClick={onSubmitNext}
                secondary
                white
              >
                Next <span>›</span>
              </Button>
            ) : null}
          </div>
        </div>
      </form>
    </CSSTransition>
  );
};

QuestionScreen.defaultProps = {
  step: { current: 0, of: 0 },
  options: [],
  answer: '',
};

export default QuestionScreen;
