import { firestore, auth } from 'api/firebaseApi';

const getUserDataFromSnapshot = (snapshot) =>
  snapshot?.data instanceof Function ? snapshot?.data() : null;

export const fetchUserRequest = async (uid) =>
  firestore
    .collection('users')
    .doc(uid)
    .get()
    .then((snapshot) => (snapshot.empty ? null : getUserDataFromSnapshot(snapshot)));

export const createUserRequest = async (uid) =>
  firestore.collection('users').doc(uid).set({}, { merge: true }).then(getUserDataFromSnapshot);

export const updateUserDataRequest = async (id, data) =>
  firestore.collection('users').doc(id).update(data);

export const updateUserRequest = async (data) => auth.currentUser.updateProfile(data);

export const fetchUserFormData = async () => firestore.collection('userFormData').get();

export const submitUserSurvey = async (id, answers) =>
  firestore.collection('users').doc(id).update({ answers });

export const sendEmailForLoginRequest = (email, url) =>
  fetch(`${process.env.REACT_APP_FUNCTIONS_URL}sendLoginLink`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ email, url }),
  });

export const checkEmailLink = async (link) => auth.isSignInWithEmailLink(link);

export const loginWithEmailLinkRequest = async (email, link) =>
  auth.signInWithEmailLink(email, link);

export const sendContactMeEmailRequest = (email, data) => {
  fetch(`${process.env.REACT_APP_FUNCTIONS_URL}sendContactMeEmail`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ email, data }),
  });
};

export const sendEmailWithResultsRequest = (email, data) => {
  fetch(`${process.env.REACT_APP_FUNCTIONS_URL}sendEmailWithResults`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ email, data }),
  });
};
