const ROUTES = {
  HOME: '/',
  REGISTRATION: '/registration',
  ADD_DETAILS: '/add-details',
  SURVEY: '/survey/:screenId?',
  RESULTS: '/results',
  PRIVANCY_POLICY: '/privacy-policy',
};

export default ROUTES;
