// used for not spamming to segment during developpent
const isNotDev = process.env.NODE_ENV !== 'development';

// uncomment for debuging analytics
// const isNotDev = true;

// PAGE
export function pageHomeVisited() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page('Home');
}

export function pageRegistrationVisited() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page('Registration');
}

export function pageAddDetailsVisited() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page('Add Details');
}

export function pageSurveyVisited() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page('Add Details');
}

export function pageSectionVisited(name) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page(name);
}

export function pageQuestionVisited(name) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page(name);
}

export function pageResultsVisited() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page('Results');
}

export function pagePrivacyPolicyVisited() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page('PrivacyPolicy');
}

export function pageNotFoundVisited() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.page('NotFound');
}

// IDENTIFY
export function identify(uid, { email, displayName }) {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify(uid, {
    uid,
    email,
    displayName,
  });
}

// TRACK
export function requestEmailToLogin(email) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Request Email To Login', { email });
}

export function registrationSucceed(email) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Registration Succeed', { email });
}

export function loginSucceed(email) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Login Succeed', { email });
}

export function loginFailed(email) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Login Failed', { email });
}

export function userInformationUpdated(uid, email) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('User Information Updated', { uid, email });
}

export function surveySubmitted(uid, email) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Survey Submitted', { uid, email });
}

export function takeSurveyClicked() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Take Survey Clicked');
}

export function contactUsClicked() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Contact Us Clicked');
}

export function shareViaLinkedInClicked() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Share Via LinkedIn Clicked');
}

export function shareViaTwitterClicked() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Share Via Twitter Clicked');
}

export function shareViaFacebookClicked() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Share Via Facebook Clicked');
}

export function shareViaMailClicked() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Share Via Mail Clicked');
}

export function showSuggestionClicked(question, suggestion) {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Show Suggestion Clicked', {
    question,
    suggestion,
  });
}

export function contactMeClicked() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Contact Me Clicked');
}

export function emailMeResultsClicked() {
  if (!window.analytics && isNotDev) {
    return;
  }

  window.analytics.track('Email Me Results Clicked');
}
