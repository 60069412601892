import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

import { Actions } from 'reducers/user';

import ROUTES from 'consts/routes';
import { getResults } from 'utils/survey';
import { pageResultsVisited, contactMeClicked, emailMeResultsClicked } from 'utils/analytics';

import CustomerExperienceLadder from 'components/CustomerExperienceLadder';
import Score from './Score';
import HighsAndLowsRow from './HighsAndLowsRow';
import GetInTouch from './GetInTouch';

import './styles.scss';

const Results = ({
  history,
  userId,
  screens,
  answers,
  greenThreshold,
  yellowThreshold,
  sendEmailWithResults,
  sendContactMeEmail,
}) => {
  useEffect(() => {
    pageResultsVisited();
  }, []);

  useEffect(() => {
    if (!userId) {
      history.push(ROUTES.REGISTRATION);
    }
  }, [userId, history]);

  const { sections, totalScore, lowestScoreSections, highestScoreSections } = useMemo(
    () => getResults(screens, answers, greenThreshold),
    [screens, answers, greenThreshold],
  );

  const onContactMeClick = useCallback(() => {
    contactMeClicked();
    sendContactMeEmail();
  }, [sendContactMeEmail]);

  const onEmailMeResultsClick = useCallback(() => {
    emailMeResultsClicked();
    sendEmailWithResults();
  }, [sendEmailWithResults]);

  return (
    <div className="results">
      <div className="results__score">
        <Score score={totalScore} label="Total score" className="results__score__bar" />
      </div>

      <section className="results__ladder">
        <h1 className="results__ladder__title">Your Customer Experience Ladder</h1>
        <CustomerExperienceLadder
          sections={sections}
          greenThreshold={greenThreshold}
          yellowThreshold={yellowThreshold}
        />
      </section>

      <section className="results__highs">
        <h2 className="results__highs__title">Your highs and lows</h2>
        {highestScoreSections.length ? (
          <div className="results__highs__container">
            <p className="subtitle">You scored highest in the following steps:</p>
            {highestScoreSections.map((section) => (
              <HighsAndLowsRow
                key={section.id}
                section={section}
                greenThreshold={greenThreshold}
                yellowThreshold={yellowThreshold}
                highest
              />
            ))}
          </div>
        ) : null}
        {lowestScoreSections.length ? (
          <div className="results__highs__container">
            <p className="subtitle">You scored lowest in the following steps:</p>
            {lowestScoreSections.map((section) => (
              <HighsAndLowsRow
                key={section.id}
                section={section}
                greenThreshold={greenThreshold}
                yellowThreshold={yellowThreshold}
              />
            ))}
          </div>
        ) : null}
      </section>

      <GetInTouch
        onContactMeClick={onContactMeClick}
        onEmailMeResultsClick={onEmailMeResultsClick}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  screens: state.survey.screens || [],
  answers: state.user.answers || {},
  greenThreshold: state.global.config?.resultPage?.greenThreshold,
  yellowThreshold: state.global.config?.resultPage?.yellowThreshold,
  userId: state.user.id || '',
});

const mapDispatchToProps = (dispatch) => ({
  sendContactMeEmail: () => dispatch(Actions.sendContactMeEmail()),
  sendEmailWithResults: () => dispatch(Actions.sendEmailWithResults()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
