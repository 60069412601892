import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import ShareBar from 'components/ShareBar';
import CustomerExperienceLadder from 'components/CustomerExperienceLadder';

import ROUTES from 'consts/routes';
import { pageHomeVisited, takeSurveyClicked } from 'utils/analytics';

import Slider from './Slider';

import './styles.scss';

const sectionBlocks = [
  {
    id: 1,
    url:
      'https://firebasestorage.googleapis.com/v0/b/cxi-dev.appspot.com/o/section-icons%2Fmanagement.svg?alt=media&token=c0c489c2-6d6e-4838-8e14-94eecfc7a8e7',
    alt: 'Management – Ensure Customer-Centricity | Customer Experience Index | BBH Stockholm',
  },
  {
    id: 2,
    url:
      'https://firebasestorage.googleapis.com/v0/b/cxi-dev.appspot.com/o/section-icons%2Finsights.svg?alt=media&token=326a7c16-abd2-4f3f-b596-6b3ba2c432d2',
    alt: 'Insights – Insights for Customer Experience | Customer Experience Index | BBH Stockholm',
  },
  {
    id: 3,
    url:
      'https://firebasestorage.googleapis.com/v0/b/cxi-dev.appspot.com/o/section-icons%2Fmarketing.svg?alt=media&token=12ee225e-539a-4692-b394-6c821e00ac13',
    alt:
      "Holistic communication as part of the customer's journey | Customer Experience Index | BBH Stockholm",
  },
  {
    id: 4,
    url:
      'https://firebasestorage.googleapis.com/v0/b/cxi-dev.appspot.com/o/section-icons%2Fprerequisites.svg?alt=media&token=adf90d66-c1d9-4d5a-b937-2cc749412555',
    alt:
      'Tech Due Diligence for improved Customer Experience | Customer Experience Index | BBH Stockholm',
  },
];

const sectionScores = [100, 75, 86, 15, 100, 55, 85];

const Home = ({ screens = [], greenThreshold, yellowThreshold }) => {
  useEffect(() => pageHomeVisited(), []);

  const sections = screens
    .filter((screen) => screen.type === 'section')
    .map((section, index) => {
      // eslint-disable-next-line no-param-reassign
      section.score = sectionScores[index];
      return section;
    });

  return (
    <div className="home">
      <div className="home__main-description">
        <section className="home__main-description__section">
          <h1 className="title">Unlock your full potential.</h1>
          <h2 className="subtitle">
            Find out where your organisation should improve to deliver customer experience that
            unlocks your full potential
          </h2>
          <div className="controls-wrapper">
            <Link
              className="do-assestment"
              label="Take the CXI self-assessment test"
              to={ROUTES.REGISTRATION}
              asButton
              arrowRight
              onClick={takeSurveyClicked}
            />
            <p>Answer the survey and learn more about your strengths and weaknesses</p>
            <p>The survey has 30 questions and takes about 15-20 minutes to answer.</p>
          </div>
        </section>
        <div className="home__main-description__steps">
          {sectionBlocks.map(({ id, url, alt }) => (
            <div key={id} className="logo">
              <img src={url} alt={alt} />
            </div>
          ))}
        </div>
      </div>
      <section className="home__purpose">
        <div className="home__purpose__info">
          <h2 className="title">Climb the Customer Experience Maturity ladder</h2>
          <p className="description">
            Master each step of the customer experience maturity ladder to unlock the full potential
            of your organisation and deliver customer experiences that grow your business.
            <br />
            <br />
            When any of these steps are out of sync with the rest of the ladder you experience
            friction and bottlenecks in your capability to push out innovation.
          </p>
        </div>

        <Slider className="home__purpose__sections" sections={sections} />
      </section>
      <div className="home__how-it-works__wrapper">
        <section className="home__how-it-works">
          <div className="home__how-it-works__info">
            <h2 className="title">How does the survey work?</h2>
            <p className="text">
              Grade your organisation by picking the statement best describing your current state. A
              score is calculated per step in the maturity ladder to see what areas need
              improvement, and you get an overall CXI score to benchmark against the industry.
              <br />
              <br />
              Take our survey to get a grade on how your organisation stands.
            </p>
          </div>
          <CustomerExperienceLadder
            className="home__how-it-works__ladder"
            sections={sections}
            greenThreshold={greenThreshold}
            yellowThreshold={yellowThreshold}
          />
        </section>
      </div>
      <section className="home__take-survey">
        <div className="home__take-survey__controls">
          <div className="wrapper">
            <h2 className="title">Take the free survey to get your CXI</h2>
            <Link
              className="do-assestment"
              label="Do the CXI self assessment"
              to={ROUTES.REGISTRATION}
              asButton
              arrowRight
            />
            <p className="info">It takes about 20 minutes over 30 questions</p>
          </div>
        </div>
        <div className="home__take-survey__share">
          <p className="title">Share the CXI survey with your collegues or friends</p>
          <ShareBar className="links" />
        </div>
      </section>
    </div>
  );
};

Home.propTypes = {
  screens: PropTypes.arrayOf(PropTypes.object),
  greenThreshold: PropTypes.number,
  yellowThreshold: PropTypes.number,
};

const mapStateToProps = (state) => ({
  screens: state.survey.screens || [],
  greenThreshold: state.global.config?.resultPage?.greenThreshold,
  yellowThreshold: state.global.config?.resultPage?.yellowThreshold,
});

export default connect(mapStateToProps)(Home);
