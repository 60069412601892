import React from 'react';
import TextTruncate from 'react-text-truncate';
import cn from 'classnames';

import './styles.scss';

const CustomerExperienceLadder = ({
  sections = [],
  greenThreshold,
  yellowThreshold,
  className,
}) => {
  const stuckIndex = sections.findIndex((section) => section.score < yellowThreshold);
  const mutableSections = [...sections];
  if (stuckIndex > -1) {
    mutableSections.splice(stuckIndex, 0, { id: 'stuck' });
  }

  return (
    <div className={cn('ladder-container', className)}>
      {mutableSections?.length
        ? mutableSections.map((section, index) => {
            if (section.id === 'stuck') {
              return (
                <p className="ladder-container__stuck-message" key="stuck-here">
                  You got stuck here
                </p>
              );
            }

            return (
              <div key={section.id} className="ladder-container__row">
                <div
                  className="ladder-container__row__section"
                  style={{
                    flex: `0 1 calc((100% - 3.625rem) - ${index}rem)`,
                  }}
                >
                  {section.imageUrl ? (
                    <div className="logo">
                      <img src={section.imageUrl} alt={section.imageAlt} />
                    </div>
                  ) : null}
                  <p className="step">Step {section.step?.current}</p>
                  <TextTruncate
                    containerClassName="section-title"
                    line={2}
                    element="p"
                    truncateText="…"
                    text={section.ladderStepTitle}
                  />
                </div>
                <div className="ladder-container__row__score">
                  <p
                    className={cn(
                      {
                        red: section.score < yellowThreshold,
                      },
                      {
                        yelow: section.score >= yellowThreshold && section.score < greenThreshold,
                      },
                      { green: section.score >= greenThreshold },
                    )}
                  >
                    {section.score}%
                  </p>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default React.memo(CustomerExperienceLadder);
