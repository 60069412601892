import React, { useEffect } from 'react';

import Link from 'components/Link';

import ROUTES from 'consts/routes';
import { pageNotFoundVisited } from 'utils/analytics';

import './styles.scss';

const NotFound = () => {
  useEffect(() => {
    pageNotFoundVisited();
  }, []);

  return (
    <div className="not-found">
      <div className="not-found__container">
        <h1 className="not-found__title">404</h1>
      </div>

      <h2 className="not-found__subtitle">Oops. This page has gone missing.</h2>
      <p className="not-found__info">
        You may have mistyped the address or the page may have moved.
      </p>
      <Link
        className="not-found__take-me-home"
        to={ROUTES.HOME}
        label="Take me home"
        asButton
        arrowRight
      />
    </div>
  );
};

export default React.memo(NotFound);
