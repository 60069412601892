import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ActionTypes as GlobalActionTypes, GlobalActions } from 'reducers/global';
import { ActionTypes, Actions } from 'reducers/survey';
import { Selectors as UserSelectors } from 'reducers/user';

import { fetchSurveyById, sendSubmissionReportRequest } from 'api/survey';
import { getScreens } from 'utils/survey';
import { getError } from 'utils/notifications';

function* fetchSurvey({ data: configData }) {
  try {
    yield put(GlobalActions.showLoader());

    const survey = yield call(fetchSurveyById, configData?.general.currentSurveyId);

    const { screens, questionsCount } = getScreens(survey.sections);

    // add a final screen to complete the survey
    screens.push({ id: 'final', type: 'final' });

    yield put(Actions.fetchSurveySuccess(screens, questionsCount, survey.version));
  } catch (e) {
    yield put(GlobalActions.showNotification(...getError('Failed to fetch survey data.', e)));
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

function* sendSubmissionReport({ data }) {
  try {
    yield put(GlobalActions.showLoader());

    const email = yield select(UserSelectors.userEmail);

    yield call(sendSubmissionReportRequest, email, data);
  } catch (e) {
    console.warn(e);
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

export default function* saga() {
  yield takeLatest(GlobalActionTypes.FETCH_CONFIG_SUCCESS, fetchSurvey);
  yield takeLatest(ActionTypes.SEND_SUBMISSION_REPORT, sendSubmissionReport);
}
