import React, { useRef, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import Link from 'components/Link';

import ROUTES from 'consts/routes';

import BBHLogo from 'assets/img/bbh-logo.svg';
import PublicisGroupeLogo from 'assets/img/publicisgroupe-logo.svg';

import './styles.scss';

const Header = ({ blackAndWhite = false }) => {
  const ref = useRef(null);
  const match = useRouteMatch({
    path: [ROUTES.REGISTRATION, ROUTES.ADD_DETAILS],
    strict: true,
  });

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0) {
        if (!ref.current.classList.contains('header--sticky')) {
          ref.current.classList.add('header--sticky');
        }
      } else {
        if (ref.current.classList.contains('header--sticky')) {
          ref.current.classList.remove('header--sticky');
        }
      }
    };

    window.document.addEventListener('scroll', onScroll);
    return () => window.document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <header ref={ref} className={cn('header', match ? 'header--black-and-white' : 'header--main')}>
      <div className="header__container">
        <Link className="header__logo" to="/" title="Home">
          <img src={BBHLogo} alt="BBHLogo" />
        </Link>
        <Link className="header__title" to="/" title="Home">
          CXI
        </Link>
        <div className="header__logo">
          <img src={PublicisGroupeLogo} alt="PublicisGroupeLogo" />
        </div>
      </div>
    </header>
  );
};

export default Header;
