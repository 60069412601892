import React from 'react';
import './styles.scss';

const getProgressSectionWidth = (section) => `${(section.done / section.of) * 100}%`;

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-wrapper">
      <div className="progress">
        <div className="progress__sections-container">
          {progress.map((section) => (
            <div key={section.id} className="progress__section">
              <div
                className="progress__section--filled"
                style={{ width: getProgressSectionWidth(section) }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
