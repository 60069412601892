import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.scss';

const Spinner = ({ isLoading }) => {
  return isLoading > 0 ? (
    <div className="spinner-wrapper">
      <CircularProgress color="inherit" size="100%" />{' '}
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  isLoading: state.global.isLoading || 0,
});

export default connect(mapStateToProps)(Spinner);
