import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import Link from 'components/Link';

import ROUTES from 'consts/routes';
import { contactUsClicked } from 'utils/analytics';

import './styles.scss';

const Footer = () => {
  const match = useRouteMatch({
    path: [ROUTES.REGISTRATION, ROUTES.ADD_DETAILS, ROUTES.HOME],
    strict: true,
  });

  if (!match?.isExact) {
    return null;
  }

  return (
    <div className="footer__wrapper">
      <footer className="footer">
        <div className="footer__content">
          <p className="title">Need help?</p>
          <p className="details">
            Contact BBH Stockholm if you have any questions or if you want help with the survey
          </p>
          <Link
            className="contact-us"
            label="Contact us"
            href="mailto:cxindex@bbh-stockholm.se"
            newTab
            asButton
            arrowRight
            onClick={contactUsClicked}
          />
        </div>
      </footer>
    </div>
  );
};

export default Footer;
