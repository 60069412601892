import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import App from 'components/App';

// import * as serviceWorker from './serviceWorker';

import createStore from 'createStore';

import history from 'utils/history';

const { store, persistor } = createStore();

const snackbarProviderProps = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  classes: {
    variantError: 'snackbar--error',
    variantSuccess: 'snackbar--success',
    root: 'snackbar',
    containerAnchorOriginBottomRight: 'snackbar-container',
  },
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SnackbarProvider {...snackbarProviderProps}>
        <Router history={history}>
          <App />
        </Router>
      </SnackbarProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
