import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_API_KEY || '',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || '',
  databaseURL: process.env.REACT_APP_DATABASE_URL || '',
  projectId: process.env.REACT_APP_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || '',
};

const firebaseApp = initializeApp(config);

export const firestore = firebaseApp.firestore();
export const auth = firebaseApp.auth();

export default firebaseApp;
