import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

export const { Types: ActionTypes, Creators: Actions } = createActions(
  {
    fetchSurvey: [],
    fetchSurveySuccess: ['screens', 'questionsCount', 'version'],
    sendSubmissionReport: ['data'],
  },
  { prefix: 'SURVEY/' },
);

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  screens: [],
  questionsCount: 0,
};

/* ------------- Reducer functions ------------- */

const fetchSurveySuccess = (state, { screens, questionsCount, version }) => ({
  ...state,
  screens,
  questionsCount,
  version,
});

/* ------------- Hook  ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [ActionTypes.FETCH_SURVEY_SUCCESS]: fetchSurveySuccess,
});
