import React, { useState, useCallback } from 'react';
import AnimateHeight from 'react-animate-height';
import cn from 'classnames';

import { showSuggestionClicked } from 'utils/analytics';

import './styles.scss';

const HighsAndLowsRow = ({ section, greenThreshold, yellowThreshold, highest = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSuggentionShown = useCallback(() => {
    if (!isOpen) {
      showSuggestionClicked(section.suggestion?.questionTitle, section.suggestion.info);
    }
    setIsOpen(!isOpen);
  }, [isOpen, section.suggestion]);

  return (
    <div className="highs-and-lows-row">
      <div className="highs-and-lows-row__main">
        <div className="highs-and-lows-row__main__score">
          <p
            className={cn(
              {
                red: section.score < yellowThreshold,
              },
              {
                yelow: section.score >= yellowThreshold && section.score < greenThreshold,
              },
              { green: section.score >= greenThreshold },
            )}
          >
            {section.score}%
          </p>
        </div>
        <div
          className={cn('highs-and-lows-row__main__info', {
            'highs-and-lows-row__main__info--highest': highest,
          })}
        >
          <p className="info__title">{section.ladderStepTitle}</p>
          {section.suggestion && !highest ? (
            <p className="info__question">{section.suggestion.questionTitle}</p>
          ) : null}
        </div>
        {!highest ? (
          <div
            className={cn('highs-and-lows-row__main__show-more', {
              'highs-and-lows-row__main__show-more--active': isOpen,
            })}
          >
            <label htmlFor={section.id}>
              {isOpen ? 'Hide recommendation' : 'Show recommendation'}
            </label>
            <button id={section.id} onClick={toggleSuggentionShown} />
          </div>
        ) : null}
      </div>
      {!highest ? (
        <AnimateHeight height={isOpen ? 'auto' : 0} duration={300}>
          <div
            className="highs-and-lows-row__suggestion"
            dangerouslySetInnerHTML={{ __html: section.suggestion.info }}
          />
        </AnimateHeight>
      ) : null}
    </div>
  );
};

export default React.memo(HighsAndLowsRow);
