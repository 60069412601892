import { createStore as сreateReduxStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from 'reducers';
import sagas from 'sagas';

const sagaMiddleware = createSagaMiddleware();

const enhancers = [];
const middleware = [sagaMiddleware];
const persistConfig = {
  version: 4, // increment it, if store datastructure changes
  key: 'root',
  storage,
  blacklist: ['global'],
};

if (process.env.NODE_ENV === 'development') {
  /* eslint-disable no-underscore-dangle */
  const devToolsExtension =
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || null;
  /* eslint-enable */
  if (devToolsExtension) {
    enhancers.push(devToolsExtension);
  }

  const logger = createLogger({
    collapsed: true,
    diff: true,
    titleFormatter: (action, time, took) =>
      `Action: ${action.type}  ${action.key ? `Key: ${action.key} ` : ''}(in ${took.toFixed(
        3,
      )} ms)`,
  });
  middleware.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const createStore = (initialState = {}) => {
  const store = сreateReduxStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);
  sagaMiddleware.run(sagas);
  return { store, persistor };
};

export default createStore;
