import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ArrowRight from 'assets/img/icons/arrow-right.svg';

import './styles.scss';

const Link = ({
  className,
  href,
  to,
  label,
  newTab,
  asButton,
  arrowRight,
  secondary,
  children,
  ...props
}) => {
  const classes = cn(asButton ? 'link-as-button' : 'link', { secondary: secondary }, className);
  return href ? (
    <a
      className={classes}
      href={href}
      {...(newTab && { target: '_blank', rel: 'noopener noreferrer' })}
      {...props}
    >
      <span>
        {label || children}
        {asButton && arrowRight ? (
          <img className="button__icon" src={ArrowRight} alt="arrow-right" />
        ) : null}
      </span>
    </a>
  ) : (
    <RouterLink className={classes} to={to} {...props}>
      <span>
        {label || children}
        {asButton && arrowRight ? (
          <img className="button__icon" src={ArrowRight} alt="arrow-right" />
        ) : null}
      </span>
    </RouterLink>
  );
};

Link.defaultProps = {
  label: '',
  children: null,
  newTab: false,
  asButton: false,
  secondary: false,
  arrowRight: false,
};

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, Object]),
  label: PropTypes.string,
  newTab: PropTypes.bool,
  asButton: PropTypes.bool,
  arrowRight: PropTypes.bool,
  secondary: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Link;
