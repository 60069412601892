import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Link from 'components/Link';

import {
  shareViaLinkedInClicked,
  shareViaTwitterClicked,
  shareViaFacebookClicked,
  shareViaMailClicked,
} from 'utils/analytics';

import { ReactComponent as LinkedIn } from 'assets/img/icons/linked-in.svg';
import { ReactComponent as Twitter } from 'assets/img/icons/twitter.svg';
import { ReactComponent as Facebook } from 'assets/img/icons/facebook.svg';
import { ReactComponent as Mail } from 'assets/img/icons/mail.svg';

import './styles.scss';

const ShareBar = ({ className }) => {
  return (
    <div className={cn('share-bar', className)}>
      <Link
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.origin}/&title=Map your ability to deliver a successful customer experience.&summary=The Customer Experience Index is a BBH Stockholm tool for mapping your organization's maturity and ability to deliver a successful customer experience. Take the test and get your score.`}
        newTab
        onClick={shareViaLinkedInClicked}
      >
        <LinkedIn alt="LinkedIn" />
      </Link>
      <Link
        href={`https://twitter.com/intent/tweet?text=Map your ability to deliver a successful customer experience. &url=${window.location.origin}/`}
        newTab
        onClick={shareViaTwitterClicked}
      >
        <Twitter alt="Twitter" />
      </Link>
      <Link
        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/`}
        newTab
        onClick={shareViaFacebookClicked}
      >
        <Facebook alt="Facebook" />
      </Link>
      <Link
        href={`mailto:?&subject=Map your ability to deliver a successful customer experience.&body=The Customer Experience Index is a BBH Stockholm tool for mapping your organization's maturity and ability to deliver a successful customer experience. Take the test and get your score ${window.location.origin}`}
        newTab
        onClick={shareViaMailClicked}
      >
        <Mail alt="Mail" />
      </Link>
    </div>
  );
};

ShareBar.propTypes = {
  className: PropTypes.string,
};

export default React.memo(ShareBar);
