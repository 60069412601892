import React, { useCallback, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { pageSectionVisited } from 'utils/analytics';

import './styles.scss';

const SectionScreen = ({
  title,
  description,
  step,
  onNextClick,
  onPrevClick,
  imageUrl,
  imageAlt = '',
}) => {
  const [active, setActive] = useState(true);

  useEffect(() => {
    pageSectionVisited(`Step: ${step.current} of ${step.of} ${title}`);
  }, [step, title]);

  const onNextButtonClick = useCallback(() => {
    setActive(false);
    setTimeout(() => {
      onNextClick();
    }, 250);
  }, [onNextClick]);

  const onPrevButtonClick = useCallback(() => {
    setActive(false);
    setTimeout(() => {
      onPrevClick();
    }, 250);
  }, [onPrevClick]);

  return (
    <CSSTransition in={active} timeout={250} classNames="fade" unmountOnExit>
      <div className="section">
        {imageUrl ? (
          <div className="section__logo">
            <img src={imageUrl} alt={imageAlt} />
          </div>
        ) : null}

        <p className="section__steps">{`Step ${step.current} of ${step.of}`}</p>
        <h1 className="section__title">{title}</h1>
        {description ? (
          <p className="section__description" dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        <Button
          className="section__go-to-next-questions"
          label="Answer questions"
          onClick={onNextButtonClick}
          arrowRight
        />
        {onPrevClick ? (
          <Button
            className="section__go-to-prev-questions"
            onClick={onPrevButtonClick}
            secondary
            white
          >
            <span>‹</span> Previous questions
          </Button>
        ) : null}
      </div>
    </CSSTransition>
  );
};

SectionScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  step: PropTypes.shape({
    current: PropTypes.number.isRequired,
    of: PropTypes.number.isRequired,
  }).isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
};

export default SectionScreen;
