import { combineReducers } from 'redux';

import { reducer as global } from './global';
import { reducer as user } from './user';
import { reducer as survey } from './survey';

export default combineReducers({
  global,
  user,
  survey,
});
