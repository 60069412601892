import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import Button from 'components/Button';

import { pagePrivacyPolicyVisited } from 'utils/analytics';

import './styles.scss';

const PrivacyPolicy = (props) => {
  useEffect(() => {
    pagePrivacyPolicyVisited();
  }, []);

  const onBackClicked = useCallback(() => props.history.goBack(), [props.history]);

  return (
    <div className="privacy-policy">
      <Button className="privacy-policy__back-buton" onClick={onBackClicked} secondary>
        <span>‹</span> Go back
      </Button>
      <h1 className="privacy-policy__title">Privacy policy</h1>
      <div className="privacy-policy__content">
        <p>
          The CXI Self-Assessment Test is provided by BBH Stockholm (registered as Monte Rosa AB),
          part of Publicis Groupe S.A. This privacy policy will explain how our organization uses
          the personal data we collect from you when you use this website.
        </p>

        <h3>What data do we collect?</h3>
        <ul>
          <li>Personal identification information provided by you yourself:</li>
          <ul>
            <li>Full Name</li>
            <li>Job Title</li>
            <li>Job Seniority</li>
            <li>Email</li>
          </ul>
          <li>
            Additional information provided by Google Analytics is anonymized by Google before we
            receive it and cannot be linked to you.
          </li>
        </ul>

        <h3>How will we use your data?</h3>
        <p>
          We use your data to get an understanding of areas within your company that need
          improvement and to provide you relevant feedback. We need your email so that we can send
          you the CXI test and offers on other products and services from BBH we think might be of
          interest to you.
        </p>
        <p>
          We may use your data after it has been anonymized for our own marketing purposes such as
          for presentations, events and other sales initiatives.
        </p>
        <p>We use analytics data to better understand how the website is used and to improve it.</p>

        <h3>How do we store your data?</h3>
        <p>
          Your data and your responses are stored by Firebase which acts as Data Processor, see{' '}
          <Link href="https://firebase.google.com/support/privacy/" newTab>
            https://firebase.google.com/support/privacy/
          </Link>
        </p>
        <p>We will keep your data for as long as the CXI service is run by us.</p>

        <h3>COOKIES</h3>
        <h3>How do we use cookies?</h3>
        <p>
          We uses cookies in a range of ways to improve your experience on our website, including
          keeping you signed in and understanding how you use our website
        </p>

        <h3>What types of cookies do we use?</h3>
        <p>There are a number of different types of cookies, our website uses:</p>
        <ul>
          <li>
            Functionality – We use these cookies so that we recognize you on our website and handle
            navigation between pages.
          </li>
          <li>
            Analytics – We use these cookies to collect information about your visit to our website.
            This data is only used to improve the website and is not shared with any external
            partners.
          </li>
        </ul>
        <p>
          You can set your browser not to accept cookies, however some of our website features may
          not function as a result.
        </p>

        <h3>Changes to our privacy policy</h3>
        <p>
          Our Company keeps its privacy policy under regular review and places any updates on this
          web page. This privacy policy was last updated on 13 July 2020.
        </p>

        <h3>How to contact us</h3>
        <p>
          If you have any questions about Our Company’s privacy policy, the data we hold on you, or
          you would like to exercise one of your data protection rights, please do not hesitate to
          contact us.
        </p>
        <p>
          Email us at:{' '}
          <Link href="mailto:cxindex@bbh-stockholm.se" newTab>
            cxindex@bbh-stockholm.se
          </Link>
        </p>
      </div>
    </div>
  );
};

PrivacyPolicy.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

// const mapStateToProps = (state) => ({
//   isLoading: state.global.isLoading > 0 || false,
//   email: state.user.email || '',
//   policyAccepted: state.user.policyAccepted || false,
// });

// const mapDispatchToProps = (dispatch) => ({
//   loginWithEmail: (email, policyAccepted) =>
//     dispatch(Actions.loginWithEmail(email, policyAccepted)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
export default React.memo(PrivacyPolicy);
