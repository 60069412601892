const checkIsQuestionValid = (question) => question.options?.length;
const getValidQuestions = (questions) => questions?.filter(checkIsQuestionValid);

export const getScreens = (sections) => {
  let questionsCount = 0;
  let questionIndex = 0;
  const screens = [];

  const validSections = sections
    .map((section) => {
      // validate questions, so each have options
      section.questions = getValidQuestions(section.questions);
      if (section.questions?.length) {
        questionsCount += section.questions.length;
        return section;
      }
      return null;
    })
    .filter(Boolean); // leave only sections with valid questions

  validSections.forEach(
    (
      {
        id: sectionId,
        title,
        description,
        questions,
        imageUrl,
        imageUrlPng,
        imageAlt,
        ladderStepTitle,
      },
      sectionIndex,
    ) => {
      const lengthWithSection = screens.push({
        type: 'section',
        id: sectionId,
        title,
        description,
        imageUrl,
        imageUrlPng,
        imageAlt,
        ladderStepTitle,
        step: { current: sectionIndex + 1, of: validSections.length },
        optionIds: [],
        questionsCount: questions.length,
      });

      questions.forEach(({ id, title, description, options }) => {
        // add to last scteen, (that is section) optionIds for easier progress trecking
        screens[lengthWithSection - 1].optionIds.push(...options.map((option) => option.id));

        screens.push({
          type: 'question',
          id,
          sectionId,
          title,
          description,
          options,
          step: { current: (questionIndex += 1), of: questionsCount },
        });
      });
    },
  );

  return { screens, questionsCount };
};

export const getProgress = (screens, answers) => {
  const progress = screens
    .map((screen) => {
      if (screen.type === 'section') {
        return {
          id: screen.id,
          done: screen.optionIds.reduce(
            (acc, optionId) =>
              Object.values(answers).some((ansver) => ansver === optionId) ? (acc += 1) : acc,
            0,
          ),
          of: screen.questionsCount,
        };
      }

      return false;
    })
    .filter(Boolean);

  return progress;
};

export const getSectionsResults = (screens, answers, withReport = false) => {
  const sectionsScores = {};
  const sectionsSuggestions = {};
  const report = [];

  screens.forEach((screen) => {
    if (screen.type === 'question') {
      const anveredOption = screen.options.find((option) => option.id === answers[screen.id]);
      const optionValue = Number(anveredOption?.value || 0);

      sectionsScores[screen.sectionId] =
        Number(sectionsScores[screen.sectionId] || 0) + optionValue;

      if (optionValue < 2) {
        sectionsSuggestions[screen.sectionId] = [
          ...(sectionsSuggestions[screen.sectionId] || []),
          { questionTitle: screen.title, value: optionValue, info: anveredOption?.suggestion },
        ];
      }

      if (withReport) {
        report.push({
          questionTitle: screen.title,
          questionDescription: screen.description,
          answerTitle: anveredOption.title,
          answerValue: optionValue,
        });
      }
    }
  });

  const suggestionPerSection = Object.entries(sectionsSuggestions).reduce(
    (acc, [sectionKey, suggestions]) => ({
      ...acc,
      [sectionKey]: suggestions?.sort((a, b) => a.value - b.value)?.[0],
    }),
    {},
  );

  return { sectionsScores, suggestionPerSection, report };
};

export const getResults = (screens, answers, greenThreshold, withReport) => {
  let summedScore = 0;
  const { sectionsScores, suggestionPerSection, report } = getSectionsResults(
    screens,
    answers,
    withReport,
  );

  const sections = screens.filter((screen) => screen.type === 'section');

  sections.forEach((section) => {
    const score = Number(
      Number((sectionsScores[section.id] / (section.questionsCount * 2)) * 100).toFixed(),
    );

    section.score = score;
    section.suggestion = suggestionPerSection[section.id];

    summedScore += score;
  });

  const totalScore = Number(Number(summedScore / sections.length).toFixed());

  const sortedSections = sections
    .filter((section) => typeof section.score === 'number')
    .sort((a, b) => a.score - b.score);

  const lowestScoreSections = sortedSections
    .filter((section) => section.score < greenThreshold)
    .slice(0, 2);

  const highestScoreSections = sortedSections
    .filter((section) => section.score >= greenThreshold)
    .reverse()
    .slice(0, 2);

  return { sections, totalScore, lowestScoreSections, highestScoreSections, report };
};
