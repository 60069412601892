import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { GlobalActions } from 'reducers/global';

let displayed = [];

const storeDisplayed = (id) => {
  displayed = [...displayed, id];
};

const removeDisplayed = (id) => {
  displayed = [...displayed.filter((key) => id !== key)];
};

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.global.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const hideNotification = useCallback(
    (key) => () => {
      dispatch(GlobalActions.hideNotification(key));
    },
    [dispatch],
  );

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        action: (key) => (
          <IconButton onClick={hideNotification(key)}>
            <CloseIcon style={{ color: '#ffffff' }} />
          </IconButton>
        ),
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(GlobalActions.removeNotification(myKey));

          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, hideNotification]);

  return null;
};

export default Notifier;
