import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

export const { Types: ActionTypes, Creators: Actions } = createActions(
  {
    loginWithEmail: ['email'],
    confirmLoginWithEmail: [],
    registerWithEmailSuccess: ['id', 'data'],
    loginWithEmailSuccess: ['id', 'data'],

    addUserInformation: ['data'],
    addUserInformationSuccess: ['data'],

    fetchUserFormData: [],
    fetchUserFormDataSuccess: ['data'],

    submitUserSurvey: [],
    submitUserSurveySuccess: ['metadata'],

    setAnswer: ['questionId', 'optionId'],

    sendContactMeEmail: [],
    sendEmailWithResults: [],
  },
  { prefix: 'USER/' },
);

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  // auth
  id: '',
  email: '',

  // firestore
  personalData: {},
  answers: {},

  // local
  error: null,
  policyAccepted: false,
};

/* ----------------- Selectors ----------------- */
export const Selectors = {
  userId: (state) => state.user.id,
  userEmail: (state) => state.user.email,
  answers: (state) => state.user.answers,
};

/* ------------- Reducer functions ------------- */

const loginWithEmail = (state, { email }) => ({
  ...INITIAL_STATE,
  email,
  policyAccepted: true,
});

const registerWithEmailSuccess = (state, { id = '', data = {} }) => ({
  ...state,
  id,
  personalData: data,
});

const loginWithEmailSuccess = (state, { id = '', data: { answers = {}, ...data } = {} }) => ({
  ...state,
  id,
  personalData: { ...state.personalData, ...data },
  answers,
});

const addUserInformation = (state) => ({
  ...state,
  error: null,
});

const addUserInformationSuccess = (state, { data = {} }) => ({
  ...state,
  personalData: { ...state.personalData, ...data },
});

const fetchUserFormDataSuccess = (state, { data = {} }) => ({
  ...state,
  formData: data,
});

const setAnswer = (state, { questionId, optionId }) => ({
  ...state,
  answers: { ...state.answers, [questionId]: optionId },
});

const submitUserSurveySuccess = (state, { metadata }) => ({
  ...state,
  answers: { ...state.answers, metadata },
});

/* ------------- Hook  ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [ActionTypes.LOGIN_WITH_EMAIL]: loginWithEmail,
  [ActionTypes.REGISTER_WITH_EMAIL_SUCCESS]: registerWithEmailSuccess,
  [ActionTypes.LOGIN_WITH_EMAIL_SUCCESS]: loginWithEmailSuccess,

  [ActionTypes.ADD_USER_INFORMATION]: addUserInformation,
  [ActionTypes.ADD_USER_INFORMATION_SUCCESS]: addUserInformationSuccess,

  [ActionTypes.SET_ANSWER]: setAnswer,

  [ActionTypes.FETCH_USER_FORM_DATA_SUCCESS]: fetchUserFormDataSuccess,
  [ActionTypes.SUBMIT_USER_SURVEY_SUCCESS]: submitUserSurveySuccess,
});
