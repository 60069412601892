import React from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const TextInput = React.forwardRef(
  ({ className, inputClassName, label, errorMessage, ...props }, ref) => {
    return (
      <div className={className}>
        {label && props.id ? (
          <label className="label" htmlFor={props.id}>
            {label}
          </label>
        ) : null}
        <input
          className={cn('input', { 'input--error': Boolean(errorMessage) }, inputClassName)}
          ref={ref}
          {...props}
        />
        <AnimateHeight height={errorMessage ? 'auto' : 0} duration={300}>
          <p className="input__error-message">{errorMessage}</p>
        </AnimateHeight>
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
  type: 'text',
};

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default TextInput;
