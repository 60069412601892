import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { GlobalActions } from 'reducers/global';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Spinner from 'components/Spinner';
import Notifier from 'components/Notifier';

import Home from 'pages/Home';
import UserRegistration from 'pages/UserRegistration';
import AddDetails from 'pages/AddDetails';
import Survey from 'pages/Survey';
import Results from 'pages/Results';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import NotFound from 'pages/NotFound';

import ROUTES from 'consts/routes';

import 'normalize.css/normalize.css';
import 'styles/_global.scss';

const App = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  useEffect(() => {
    if (history.action === 'POP' || history.location.hash) {
      // leave scroll position if we go back
      // prevent scrolling to top by hach (rare cases)
      return;
    }
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    // fetch config in any always to ensure it's up to date
    props.fetchConfig();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <main className="content-wrapper">
        <Switch>
          <Route path={ROUTES.REGISTRATION} exact component={UserRegistration} />
          <Route path={ROUTES.ADD_DETAILS} exact component={AddDetails} />
          <Route path={ROUTES.SURVEY} exact component={Survey} />
          <Route path={ROUTES.RESULTS} exact component={Results} />
          <Route path={ROUTES.PRIVANCY_POLICY} exact component={PrivacyPolicy} />
          <Route path={ROUTES.HOME} exact component={Home} />
          <Route component={NotFound} />
        </Switch>
      </main>

      <Footer />
      <Spinner />
      <Notifier />
    </>
  );
};

App.propTypes = {
  fetchConfig: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  fetchConfig: () => dispatch(GlobalActions.fetchConfig()),
});

export default connect(null, mapDispatchToProps)(App);
