import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from 'components/Button';

import './styles.scss';

const getPositionX = (index, active) => {
  if (index < active) {
    return (active - index) * -80;
  }

  if (index > active) {
    return (index - active) * 80;
  }

  return 0;
};

const getPositionY = (index, active) => {
  if (index < active) {
    return (active - index) * 20;
  }

  if (index > active) {
    return (index - active) * 20;
  }
  return 0;
};

const getPositionZ = (index, active) => {
  if (index < active) {
    return (active - index) * -1;
  }

  if (index > active) {
    return (index - active) * -1;
  }

  return 0;
};

const getTransform = (index, active) => {
  const current = active;

  return `translate3d(${getPositionX(index, current)}px, ${getPositionY(
    index,
    current,
  )}px, ${getPositionZ(index, current)}px) `;
};

const getOpacity = (index, active) => {
  if (index < active) {
    return 0.7 - (active - index) * 0.12;
  }

  if (index > active) {
    return 0.7 - (index - active) * 0.12;
  }

  return 1;
};

const Slider = ({ sections, className }) => {
  const sectionsWrapperRef = useRef();
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(180);

  const smallerTablet = useMediaQuery('(max-width:768px)');
  const largerTablet = useMediaQuery('(min-width:768px)');
  const largerLaptopMiddle = useMediaQuery('(min-width:1300px)');

  const getSlideWidth = useCallback(() => {
    if (largerLaptopMiddle) {
      if (slideWidth !== 350) setSlideWidth(350);
      return 350;
    }
    if (largerTablet) {
      if (slideWidth !== 268) setSlideWidth(268);
      return 268;
    }
    if (smallerTablet) {
      if (slideWidth !== 180) setSlideWidth(180);
      return 180;
    }
    return 0;
  }, [largerTablet, largerLaptopMiddle, smallerTablet, slideWidth]);

  const slidesCount = sections.length;
  getSlideWidth();

  const onPrev = useCallback(() => {
    if (sectionsWrapperRef.current) {
      sectionsWrapperRef.current.scrollTo(slideWidth * (activeSlide - 1), 0);
      setActiveSlide(activeSlide - 1);
    }
  }, [activeSlide, slideWidth]);

  const onNext = useCallback(() => {
    if (sectionsWrapperRef.current) {
      sectionsWrapperRef.current.scrollTo(slideWidth * (activeSlide + 1), 0);
      setActiveSlide(activeSlide + 1);
    }
  }, [activeSlide, slideWidth]);

  return (
    <div className={cn('slider', className)}>
      <div className="slider__container" ref={sectionsWrapperRef}>
        {slidesCount
          ? sections.map(
              (
                { id, imageUrl, imageAlt, step: { current: step } = {}, ladderStepTitle },
                index,
              ) => (
                <div
                  key={id}
                  className="slider__item"
                  style={{
                    transform: getTransform(index, activeSlide),
                    opacity: getOpacity(index, activeSlide),
                  }}
                >
                  <div className="img-wrapper">
                    <img src={imageUrl} alt={imageAlt} />
                  </div>
                  <p className="step">Step {step}</p>
                  <h3 className="title">{ladderStepTitle}</h3>
                </div>
              ),
            )
          : null}
      </div>
      <div className="slider__controls">
        <Button onClick={onPrev} disabled={activeSlide < 1}>
          <span className="controls-icon controls-icon--back" />
        </Button>
        <Button onClick={onNext} disabled={activeSlide >= slidesCount - 1}>
          <span className="controls-icon controls-icon--next" />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Slider);
