import { firestore } from 'api/firebaseApi';

// eslint-disable-next-line import/prefer-default-export
export const fetchSurveyById = async (id) =>
  firestore
    .collection('surveys')
    .doc(id)
    .get()
    .then((snapshot) => (snapshot.empty ? null : snapshot.data()));

export const sendSubmissionReportRequest = (email, data) => {
  fetch(`${process.env.REACT_APP_FUNCTIONS_URL}sendSubmissionReport`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ email, data }),
  });
};
