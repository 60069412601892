import { parse } from 'qs';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import ROUTES from 'consts/routes';

import { GlobalActions } from 'reducers/global';
import { ActionTypes, Actions, Selectors } from 'reducers/user';
import { Actions as SurveyActions } from 'reducers/survey';
import {
  requestEmailToLogin,
  registrationSucceed,
  loginSucceed,
  loginFailed,
  identify,
  userInformationUpdated,
  surveySubmitted,
} from 'utils/analytics';
import history from 'utils/history';
import { getResults } from 'utils/survey';

import {
  fetchUserRequest,
  createUserRequest,
  updateUserRequest,
  updateUserDataRequest,
  fetchUserFormData,
  submitUserSurvey,
  sendEmailForLoginRequest,
  checkEmailLink,
  loginWithEmailLinkRequest,
  sendContactMeEmailRequest,
  sendEmailWithResultsRequest,
} from 'api/user';
import { getError, getSuccess } from 'utils/notifications';

function* loginWithEmail({ email }) {
  try {
    yield put(GlobalActions.showLoader());

    const response = yield call(sendEmailForLoginRequest, email, window.location.origin);

    if (response.status !== 200) {
      throw new Error(response);
    }

    yield call(requestEmailToLogin, email);
    yield put(
      GlobalActions.showNotification(
        ...getSuccess('We have sent you an email with your personal link'),
      ),
    );
  } catch (e) {
    yield put(GlobalActions.showNotification(...getError('Failed to send you a link', e)));
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

function* confirmLoginWithEmail() {
  // const email = yield select(Selectors.userEmail);

  // take auth stuff and clear it ASAP
  const locationHref = window.location.href;
  history.replace({ search: '' });

  const { emas: email } = parse(locationHref.split('?')[1]);

  try {
    yield put(GlobalActions.showLoader());

    // check if we have user email
    // if (!email) {
    //   throw Error('No email privided. Please login.');
    // }

    // do nothing  user  is signed in
    const userId = yield select(Selectors.userId);

    if (userId && !history.location.search) {
      return;
    }

    // check if link is valid
    const isValid = yield call(checkEmailLink, locationHref);

    if (!isValid) {
      throw Error('Confirmation link is not walid');
    }

    const { user } = yield call(loginWithEmailLinkRequest, email, locationHref);

    if (user.uid) {
      const userData = yield call(fetchUserRequest, user.uid);

      if (userData) {
        const surveyId = yield select((state) => state.global.config.general.currentSurveyId);

        userData.answers = userData.answers?.[surveyId] || {};

        yield put(
          Actions.loginWithEmailSuccess(user.uid, { fullName: user.displayName, ...userData }),
        );
        yield call(identify, user.uid, { email, displayName: user.displayName || '' });
        yield call(loginSucceed, email);
      } else {
        yield call(createUserRequest, user.uid);

        yield put(
          Actions.registerWithEmailSuccess(user.uid, { fullName: user.displayName, email }),
        );
        yield call(identify, user.uid, { email });
        yield call(registrationSucceed, email);
      }
      yield put(GlobalActions.showNotification(...getSuccess('You have signed in successfully')));
    } else throw Error("Can't login");
  } catch (e) {
    yield call(loginFailed, email);

    yield put(GlobalActions.showNotification(...getError(e.message, e)));

    history.push(ROUTES.REGISTRATION);
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

function* addUserInformation({ data }) {
  try {
    yield put(GlobalActions.showLoader());

    const userId = yield select(Selectors.userId);
    const email = yield select(Selectors.userEmail);

    // ensure that we won't send undefined values
    const dataToSend = {
      companyName: data.companyName ?? '',
      companyIndustry: data.companyIndustry ?? '',
      companySize: data.companySize ?? '',
      jobSeniority: data.jobSeniority ?? '',
      jobRole: data.jobRole ?? '',
    };

    yield call(updateUserDataRequest, userId, dataToSend);
    if (data.fullName) {
      yield call(updateUserRequest, { displayName: data.fullName });
    }

    yield call(userInformationUpdated, userId, email);
    yield put(Actions.addUserInformationSuccess({ fullName: data.fullName || '', ...dataToSend }));
    yield put(
      GlobalActions.showNotification(
        ...getSuccess('Your personal information is saved successfully'),
      ),
    );

    history.push(ROUTES.SURVEY.replace('/:screenId', ''));
  } catch (e) {
    yield put(
      GlobalActions.showNotification(...getError('Failed to save your personal information', e)),
    );
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

function* userFormDataRequest() {
  try {
    yield put(GlobalActions.showLoader());

    const rawFormData = yield call(fetchUserFormData);

    if (rawFormData.empty) {
      throw new Error('User form data is empty');
    } else {
      const formData = Object.fromEntries(
        // create object from id and data
        rawFormData.docs.map((doc) => [
          doc.id,
          [
            // create data as arrey for selects
            ...Object.entries(doc.data()).map(([key, data]) => {
              return {
                value: key,
                label: data?.title || '',
                // if there is order property we add it too
                ...(data?.order && { order: data.order }),
              };
            }),
          ],
        ]),
      );

      formData.companyIndustry = formData.companyIndustry.sort((a, b) => a.order - b.order);
      formData.companySize = formData.companySize.sort((a, b) => a.order - b.order);
      formData.jobSeniority = formData.jobSeniority.sort((a, b) => a.order - b.order);
      formData.jobRoles = formData.jobRoles.sort((a, b) => a.order - b.order);

      yield put(Actions.fetchUserFormDataSuccess(formData));
    }
  } catch (e) {
    yield put(GlobalActions.showNotification(...getError('Failed to fetch form data', e)));
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

function* submitUserSurveyRequest() {
  try {
    yield put(GlobalActions.showLoader());

    const userId = yield select(Selectors.userId);
    const email = yield select(Selectors.userEmail);
    const answers = yield select(Selectors.answers);
    const formData = yield select((state) => state.user.formData);
    const surveyId = yield select((state) => state.global.config.general.currentSurveyId);
    const greenThreshold = yield select((state) => state.global.config?.resultPage?.greenThreshold);
    const yellowThreshold = yield select(
      (state) => state.global.config?.resultPage?.yellowThreshold,
    );
    const screens = yield select((state) => state.survey.screens);
    const version = yield select((state) => state.survey.version);

    const { sections, totalScore, lowestScoreSections, highestScoreSections, report } = yield call(
      getResults,
      screens,
      answers,
      greenThreshold,
      true,
    );

    const metadata = {
      totalScore,
      surveyVersion: version,
      ...(!answers.metadata?.createdAt && { createdAt: new Date().toISOString() }),
      ...(answers.metadata?.createdAt && {
        createdAt: answers.metadata.createdAt,
        modifiedAt: new Date().toISOString(),
      }),
    };

    const data = {
      sections,
      totalScore,
      lowestScoreSections,
      highestScoreSections,
      greenThreshold,
      yellowThreshold,
      formData,
      report,
      metadata,
    };

    yield call(submitUserSurvey, userId, { [surveyId]: { ...answers, metadata } });
    yield call(surveySubmitted, userId, email);

    yield put(SurveyActions.sendSubmissionReport(data));
    yield put(Actions.submitUserSurveySuccess(metadata));

    yield put(GlobalActions.hideLoader());
    yield put(GlobalActions.showNotification(...getSuccess('Your answers are saved successfully')));

    history.push(ROUTES.RESULTS);
  } catch (e) {
    GlobalActions.showNotification(...getError('Failed to save your answers', e));
    yield put(GlobalActions.hideLoader());
  }
}

function* sendContactMeEmail() {
  try {
    yield put(GlobalActions.showLoader());
    const email = yield select(Selectors.userEmail);
    const screens = yield select((state) => state.survey.screens);
    const answers = yield select((state) => state.user.answers);
    const formData = yield select((state) => state.user.formData);

    const greenThreshold = yield select((state) => state.global.config?.resultPage?.greenThreshold);
    const yellowThreshold = yield select(
      (state) => state.global.config?.resultPage?.yellowThreshold,
    );

    const { sections, totalScore, lowestScoreSections, highestScoreSections, report } = yield call(
      getResults,
      screens,
      answers,
      greenThreshold,
      true,
    );

    const data = {
      sections,
      totalScore,
      lowestScoreSections,
      highestScoreSections,
      greenThreshold,
      yellowThreshold,
      formData,
      report,
    };

    yield call(sendContactMeEmailRequest, email, data);

    yield put(
      GlobalActions.showNotification(
        ...getSuccess("Thank you for contacting us. We'll be in touch shortly"),
      ),
    );
  } catch (e) {
    yield put(GlobalActions.showNotification(...getError('Failed to send request', e)));
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

function* sendEmailWithResults() {
  try {
    yield put(GlobalActions.showLoader());

    const email = yield select(Selectors.userEmail);
    const screens = yield select((state) => state.survey.screens);
    const answers = yield select((state) => state.user.answers);

    const greenThreshold = yield select((state) => state.global.config?.resultPage?.greenThreshold);
    const yellowThreshold = yield select(
      (state) => state.global.config?.resultPage?.yellowThreshold,
    );

    const { sections, totalScore, lowestScoreSections, highestScoreSections } = yield call(
      getResults,
      screens,
      answers,
      greenThreshold,
      false,
    );

    const data = {
      sections,
      totalScore,
      lowestScoreSections,
      highestScoreSections,
      greenThreshold,
      yellowThreshold,
    };

    yield call(sendEmailWithResultsRequest, email, data);
    // yield call(requestEmailToLogin, email);

    yield put(GlobalActions.showNotification(...getSuccess('We have sent you an email')));
  } catch (e) {
    yield put(GlobalActions.showNotification(...getError('Failed to send you an email', e)));
  } finally {
    yield put(GlobalActions.hideLoader());
  }
}

export default function* saga() {
  yield takeLatest(ActionTypes.LOGIN_WITH_EMAIL, loginWithEmail);
  yield takeLatest(ActionTypes.CONFIRM_LOGIN_WITH_EMAIL, confirmLoginWithEmail);

  yield takeLatest(ActionTypes.ADD_USER_INFORMATION, addUserInformation);
  yield takeLatest(ActionTypes.FETCH_USER_FORM_DATA, userFormDataRequest);

  yield takeLatest(ActionTypes.SUBMIT_USER_SURVEY, submitUserSurveyRequest);

  yield takeLatest(ActionTypes.SEND_CONTACT_ME_EMAIL, sendContactMeEmail);
  yield takeLatest(ActionTypes.SEND_EMAIL_WITH_RESULTS, sendEmailWithResults);
}
