import React from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const Checkbox = React.forwardRef(({ className, errorMessage, label, id, ...props }, ref) => {
  return (
    <div className={cn('checkbox', { 'checkbox--error': Boolean(errorMessage) }, className)}>
      <input className="checkbox__input" type="checkbox" id={id} ref={ref} {...props} />
      <label className="checkbox__label" htmlFor={id}>
        {label}
        <AnimateHeight height={errorMessage ? 'auto' : 0} duration={300}>
          <p className="checkbox__error-message">{errorMessage}</p>
        </AnimateHeight>
      </label>
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Checkbox;
