export const getSuccess = (message) => {
  const key = new Date().getTime() + Math.random();

  return [
    key,
    {
      message,
      options: {
        key,
        variant: 'success',
      },
      autoHideDuration: 2500,
      preventDuplicate: true,
    },
  ];
};

export const getError = (message, e) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(e);
  }

  const key = new Date().getTime() + Math.random();

  return [
    key,
    {
      message,
      options: {
        key,
        variant: 'error',
      },
      autoHideDuration: 5000,
      preventDuplicate: true,
    },
  ];
};
